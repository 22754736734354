import { FormattedMessage } from 'react-intl';
import {
  NoSelectionMessage,
  SelectNoOptionsMessage,
} from '../../i18n/commonMessages';

export const organizationSelectLabels = {
  toggleModeOptionLabel: (
    <FormattedMessage
      defaultMessage={'Select only single units'}
      description={
        'Organisation select - toggle switch that enables single unit select mode'
      }
    />
  ),
  toggleModeWarningLabel: (
    <FormattedMessage
      defaultMessage={"Only this unit's direct members are selected."}
      description={
        "Organisation select - only selected unit's members are selected, not sub unit members"
      }
    />
  ),
  noOptionsMessage: () => <SelectNoOptionsMessage />,
  placeholder: <NoSelectionMessage />,
};
