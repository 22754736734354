import styled from '@emotion/styled';
import { Notification, NotificationProps } from '@laasco/ui-lib';
import { Info } from '@laasco/ui-lib/icons';

const InfoLarge = styled(Info)`
  width: 40px;
  height: 40px;
`;

export const InfoMessage = (
  props: Omit<NotificationProps, 'notificationType' | 'size' | 'icon'>,
) => (
  <Notification
    notificationType="neutral"
    size="medium"
    icon={<InfoLarge />}
    {...props}
  />
);
