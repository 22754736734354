import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Radio, RadioProps } from '@laasco/ui-lib';
import { mediaQuery } from '@laasco/ui-lib/constants';

const Wrapper = styled.div`
  display: flex;
  gap: 32px;

  @media screen and ${mediaQuery.medium} {
    flex-direction: column;
  }
`;

export interface RadioOption<Value> {
  value: Value;
  label: ReactNode;
}

export interface RadioGroupProps<Value>
  extends Omit<RadioProps, 'onChange' | 'value'> {
  options: RadioOption<Value>[];
  value?: Value;
  onChange: (newValue: Value, option: RadioOption<Value>) => void;
  className?: string;
}

export const RadioGroup = <Value,>({
  options,
  value,
  onChange,
  className,
  ...inputProps
}: RadioGroupProps<Value>) => (
  <Wrapper role="radiogroup" className={className}>
    {options.map((option) => (
      <Radio
        {...inputProps}
        key={`${option.value}`}
        value={`${option.value}`}
        checked={value === option.value}
        onChange={() => onChange(option.value, option)}
      >
        {option.label}
      </Radio>
    ))}
  </Wrapper>
);
