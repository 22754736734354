import styled from '@emotion/styled';
import { withLabel, WithLabelProps } from '@laasco/ui-lib';
import { useField } from 'formik';
import { RadioGroup, RadioGroupProps } from './RadioGroup';

const RadioGroupWithLabel = withLabel(RadioGroup);

interface RadioGroupFieldProps<Value>
  extends Omit<RadioGroupProps<Value>, 'onChange'> {
  name: string;
  className?: string;
  onChange?: RadioGroupProps<unknown>['onChange'];
}

const AbstractRadioGroupField = ({
  Component,
  ...props
}: RadioGroupFieldProps<unknown> & {
  Component: typeof RadioGroupWithLabel;
}) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <Component
      {...props}
      value={field.value}
      onChange={(value, option) => {
        helpers.setValue(value);
        props.onChange?.(value, option);
      }}
      onBlur={field.onBlur}
      errorMessage={meta.touched ? meta.error : null}
    />
  );
};

export const RadioGroupField = <Value,>(
  props: WithLabelProps<RadioGroupFieldProps<Value>>,
) => <AbstractRadioGroupField Component={RadioGroupWithLabel} {...props} />;

const VerticalRadioGroupWithLabel = withLabel(styled(RadioGroup)`
  flex-direction: column;
  gap: 16px;
`);

export const VerticalRadioGroupField = <Value,>(
  props: WithLabelProps<RadioGroupFieldProps<Value>>,
) => (
  <AbstractRadioGroupField Component={VerticalRadioGroupWithLabel} {...props} />
);
