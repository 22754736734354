import { useMemo } from 'react';
import { useField } from 'formik';
import { Select, SelectProps, withLabel, WithLabelProps } from '@laasco/ui-lib';
import { OptionType } from '@laasco/ui-lib/select/common/OptionType';
import _ from 'lodash';
import { SelectMessage } from '../../i18n/commonMessages';

const SelectWithLabel = withLabel(Select);

export const SelectField = (
  props: WithLabelProps<SelectProps<unknown>> & { name: string },
) => {
  const [field, meta, helpers] = useField(props.name);

  const value = useMemo(() => {
    return (
      (props.options as OptionType[])?.find(({ value }) =>
        _.isEqual(value, field.value),
      ) || null
    );
  }, [field.value, props.options]);

  return (
    <SelectWithLabel
      {...props}
      value={value}
      onChange={(newValue) => helpers.setValue(newValue?.value)}
      onBlur={() => helpers.setTouched(true)}
      placeholder={<SelectMessage />}
      errorMessage={meta.touched ? meta.error : null}
      dataTest={props.dataTest || props.name}
    />
  );
};
