import { useMemo } from 'react';
import {
  DateInput,
  DateInputProps,
  FormItemLabelProps,
  WithLabelProps,
} from '@laasco/ui-lib';
import { useField } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';

export interface SingleDatePickerFieldProps extends FormItemLabelProps {
  datePickerProps?: Partial<DateInputProps['datePickerProps']>;
  name: string;
  dataTest?: string;
}

export const SingleDatePickerField = ({
  datePickerProps,
  dataTest,
  ...inputProps
}: WithLabelProps<SingleDatePickerFieldProps>) => {
  const [field, meta, helpers] = useField<Date | null | undefined>(
    inputProps.name,
  );
  const intl = useIntl();

  return (
    <DateInput
      datePickerProps={{
        ...datePickerProps,
        date: useMemo(
          () => (field.value ? moment(field.value) : null),
          [field.value],
        ),
        onDateChange: (date) => helpers.setValue(date ? date.toDate() : null),
        onClose: () =>
          // Without the timeout, formik will validate the previous value and fail
          setTimeout(() => helpers.setTouched(true), 0),
      }}
      textFieldProps={{
        ...inputProps,
        errorMessage: meta.touched ? meta.error : null,
        placeholder: intl.formatMessage({
          defaultMessage: 'Date',
          description: 'Date input field placeholder',
        }),
      }}
      data-test={dataTest}
    />
  );
};
