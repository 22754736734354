import { useMemo } from 'react';
import { useField } from 'formik';
import {
  MultiSelect,
  MultiSelectProps,
  withLabel,
  WithLabelProps,
} from '@laasco/ui-lib';
import { OptionType } from '@laasco/ui-lib/select/common/OptionType';
import {
  NoSelectionMessage,
  SelectNoOptionsMessage,
} from '../../i18n/commonMessages';

const MultiSelectWithLabel = withLabel(MultiSelect);

export const MultiSelectField = (
  props: WithLabelProps<MultiSelectProps> & { name: string },
) => {
  const [field, meta, helpers] = useField(props.name);

  const value = useMemo(() => {
    const valuesSet = new Set(field.value || []);
    return (props.options as OptionType[])?.filter(({ value }) =>
      valuesSet.has(value),
    );
  }, [field.value, props.options]);

  return (
    <MultiSelectWithLabel
      {...props}
      value={value}
      onChange={(newValue) => helpers.setValue(newValue.map((v) => v.value))}
      onBlur={() => helpers.setTouched(true)}
      placeholder={props.placeholder ?? <NoSelectionMessage />}
      noOptionsMessage={() => <SelectNoOptionsMessage />}
      errorMessage={meta.touched ? meta.error : null}
    />
  );
};
