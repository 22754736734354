import { useMemo } from 'react';
import { useField } from 'formik';
import {
  OrganizationSelectProps,
  withLabel,
  WithLabelProps,
} from '@laasco/ui-lib';
import {
  NoSelectionMessage,
  SelectNoOptionsMessage,
} from '../../i18n/commonMessages';
import { LocalizedOrganizationSelect } from '../inputs/LocalizedOrganizationSelect';

export type TreeSelectValue = { id: string; includeSubtree: boolean };

const OrganizationSelectWithLabel = withLabel(LocalizedOrganizationSelect);

const inferSelectsDescendantsState = (items: TreeSelectValue[] | undefined) =>
  items && items.length > 0 ? items.some((item) => item.includeSubtree) : true;

export type OrganizationMultiSelectFieldProps =
  WithLabelProps<OrganizationSelectProps> & {
    name: string;
    placeholder?: React.ReactNode;
  };

export const OrganizationMultiSelectField = (
  props: OrganizationMultiSelectFieldProps,
) => {
  const [field, meta, helpers] = useField(props.name);

  const value = useMemo(() => {
    const valuesSet = new Set(
      (field.value || []).map((v: TreeSelectValue) => v.id),
    );
    return props.treeData.filter((item) => valuesSet.has(item.id));
  }, [field.value, props.treeData]);

  return (
    <OrganizationSelectWithLabel
      {...props}
      defaultSelectsDescendants={inferSelectsDescendantsState(field.value)}
      value={value}
      onChange={(newValue, selectsDescendats) =>
        helpers.setValue(
          newValue.map((v) => ({
            id: v.id,
            includeSubtree: selectsDescendats,
          })),
        )
      }
      onBlur={() => helpers.setTouched(true)}
      placeholder={props.placeholder ?? <NoSelectionMessage />}
      noOptionsMessage={() => <SelectNoOptionsMessage />}
      errorMessage={meta.touched ? meta.error : null}
    />
  );
};
