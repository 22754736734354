import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Checkbox, CheckboxProps } from '@laasco/ui-lib';

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export interface CheckboxOption<Value> {
  value: Value;
  label: ReactNode;
  disabled?: boolean;
}

export interface CheckboxGroupProps<Value>
  extends Omit<CheckboxProps, 'onChange' | 'value'> {
  options: CheckboxOption<Value>[];
  value: Set<Value>;
  onChange: (newValues: Set<Value>) => void;
  className?: string;
}

export const CheckboxGroup = <Value extends string>({
  options,
  value,
  onChange,
  className,
  ...inputProps
}: CheckboxGroupProps<Value>) => (
  <CheckboxGroupContainer role="group" className={className}>
    {options.map((option) => (
      <Checkbox
        {...inputProps}
        id={option.value}
        key={option.value}
        value={option.value}
        checked={value.has(option.value)}
        disabled={option.disabled}
        onChange={() => {
          const newValues = new Set(value);

          if (newValues.has(option.value)) {
            newValues.delete(option.value);
          } else {
            newValues.add(option.value);
          }

          onChange(newValues);
        }}
      >
        {option.label}
      </Checkbox>
    ))}
  </CheckboxGroupContainer>
);
