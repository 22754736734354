import {
  withLabel,
  TextField as TextFieldInput,
  TextFieldProps as TextFieldInputProps,
  WithLabelProps,
} from '@laasco/ui-lib';
import { useField } from 'formik';
import _ from 'lodash';
import { ReactNode } from 'react';

const TextFieldWithLabel = withLabel(TextFieldInput);

interface TextFieldProps extends Omit<TextFieldInputProps, 'onChange'> {
  name: string;
  renderError?: (error: string) => ReactNode;
}

export const TextField = ({
  name,
  renderError,
  ...rest
}: WithLabelProps<TextFieldProps>) => {
  const [field, meta] = useField(name);
  const error = meta.touched ? meta.error : null;

  return (
    <TextFieldWithLabel
      {...rest}
      {...field}
      errorMessage={error ? (renderError || _.identity)(error) : null}
    />
  );
};
